body {
    margin: 0;
    font-family: 'Titillium Web', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Titillium Web', sans-serif;
}

.date-range-picker {
    border-color: #61dafb;
    border-width: 1px;
    border-radius: 25px;

}
